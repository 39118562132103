$brand-color-dark-1: #19212b;
$brand-color-dark-2: #262f3d;
$brand-color-gray: #6c6c6c;
$brand-color-header: #e2e2e2;
$brand-color-light-gray: #f6f6f6;
$brand-color-medium-gray: #c4c4c4;
$brand-color-main: #19b1b4;
$brand-color-lightgrey: #e8e8e8;
$brand-color-white: #fafafa;
$brand-color-light-purple: #e5ebff;
$brand-typeface-main: "Roboto", sans-serif;
$brand-hovered-map-item: #67dddd;
$brand-light-gray: #f6f6f6;
$brand-blueish-gray: #8c9095;
$brand-washed-out-red: #ff00000f;
$brand-green-success: #4caf50;
$brand-red-rejected: #f44336;
$brand-blue-completed: #2196f3;
$brand-yellow-upcoming: yellow;
$brand-ambar-waiting: #ffc107;
$brand-yellow-warning: #dbcc46;
$brand-yellow-highlight: #c9a61d;

// How can I use ant less variables?
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

$padding-minimum: 1px;
$padding-small: 2px;
$padding-default: 4px;
$padding-large: 8px;
$padding-extra-large: 16px;
$padding-double-extra-large: 20px;

$border-radius-default: 12px;

$thumbnail-width: 40px;
