.usageTable {
  padding-top: 1rem;
  border-collapse: collapse;
  width: 100%;

  tbody {
    width: 100%;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    height: 100%;
  }
  th {
    text-align: center;
    background-color: #f2f2f2;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .center-td-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    height: 100%;
  }
}
