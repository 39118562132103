.ant-modal-title .ant-design-draggable-modal-title {
  padding: 0 16px;
}

.ant-design-draggable-modal .ant-modal-body {
  padding: 0 16px;
  min-width: 650px;
}

.ant-design-draggable-modal-resize-handle {
  display: none;
}

.ant-design-draggable-modal .ant-modal {
  justify-content: center;
  height: 100%;
}
