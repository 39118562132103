td.ant-table-column-sort {
  background: #141414;
}

.pointer {
  cursor: pointer;
}

.activeEnvironment {
  background-color: #19b1b410;
}

.small-pad {
  padding: 2px;
}
