.selected-code-info-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 1rem;
  border: 1px solid #000;
  border-radius: 5px;
  // background-color: #fff;
  gap: 1rem;
}
