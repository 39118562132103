.team-usage-scores-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.usage-card {
  padding: 1rem;
  font-weight: 700;
  width: 300px;
}
