@import "../../../scss/variables";

.SubscribablesList {
  .list-search-input {
    margin-bottom: $padding-extra-large;
    .ant-input {
      padding: $padding-large;
      min-height: 40px;
    }
  }

  .subscribables-list {
    max-height: 60vh;
    overflow-y: auto;
    list-style-type: none;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    margin-left: $padding-extra-large;
    margin-bottom: $padding-extra-large;
  }
}
