.team-map-card {
  width: 200px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5;
}
