@import "../../../../scss/variables";

div.event-details,
.event-details.ant-row {
  margin-bottom: $padding-default;
}

.event-details {
  .tag {
    border-radius: $padding-extra-large;
    color: white;
    border-color: $brand-color-medium-gray;
    border-radius: 20px;
    padding: 2px 12px;
    margin-left: $padding-large;
    margin-bottom: $padding-default;
  }

  .capitalize:first-letter {
    text-transform: uppercase;
  }

  .display-wrapper {
    margin-left: auto;
    display: flex;
    .display {
      background: #f8f3e1;
      border-color: #f8f3e1;
      font-weight: bold;
      color: $brand-yellow-highlight;
    }
  }

  .date-resource {
    margin-right: $padding-large;
  }

  .icon-calendar {
    position: relative;
    bottom: $padding-default;
  }
}

#activityFeed,
#reportsException,
#noDrawer {
  padding: 0 10px;

  hr {
    margin: 8px auto;
  }
  .markdown {
    align-self: center;
  }
  .markdown p {
    margin-bottom: 0;
  }

  .drawer-icons {
    margin-right: $padding-extra-large;
    align-self: center;

    .circle-icon {
      border-radius: 20px;
      padding: 6px;
      color: #fff;
      background-color: #51b4ff;
    }

    .circle-icon.archive-icon {
      background-color: #eb5049;
    }
    .circle-icon.unarchive-icon {
      background-color: #f7b110;
    }

    .circle-icon.plus-icon {
      padding: 2px;
      font-size: 20px;
      background-color: #61da93;
    }
    .circle-icon.minus-icon {
      padding: 2px;
      font-size: 20px;
      background-color: #eb5049;
    }
    .circle-icon.completed-icon {
      padding: 2px;
      font-size: 20px;
      background-color: #16108a;
    }
    .circle-icon.start-icon {
      padding: 2px;
      font-size: 20px;
      background-color: #4caf50;
    }

    .circle-icon.warning-icon {
      background-color: #eb5049;
      width: 34px;
      height: 34px;
      font-size: 20px;
    }
  }

  .markdown-link {
    font-weight: bold;
    text-decoration: underline;
    color: #51b4ff;
  }
  .light-grey {
    color: $brand-blueish-gray;
    margin-top: 8px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.drawer {
  .drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.activity-feed-icon {
  width: 0.8rem;
  height: 0.8rem;
}
